footer {
  background-color: $color-gray;

  p {
    margin: 0;
    padding: 15px 0;
    font-size: $font-1125;
  }

  @media (max-width: 767px) {
    p {
      font-size: $font-1;
    //  padding: 15px 0;
    }
  }
}

.contactSection {
  p.fb {
    font-weight: 700;


    svg {
      margin-left: 5px;
    }
  }

  ul {
   /* display: table;

    text-align: left;*/
    margin: 15px 0;

    li {
      padding: 10px 0 0;

      svg {
        margin-right: 15px;
      }

      span {
        font-size: $font-1125;
        font-weight: 700;
      }
    }
  }

  @media (max-width: 767px) {
    ul {
      margin: 20px 0;
    }
    p.fb {
      margin: 0;
      text-align: left;
      font-size: $font-1125;
    }
  }
}