#services {
  .services {
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.16);

    .serviceDescription {
      padding: 184px 77px;
      position: relative;
      .descriptionText{
        position: relative;
        z-index: 5;
      }
      &:after {
        content: '';
        // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, #fff 100%);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #FFFFFF;
        opacity: 0.3;
      }

      h3 {
        text-transform: uppercase;
      }
    }

    .allServices {
      .row {
        margin: 46px -15px;
        display: flex;
        align-items: center;
      }

      .shortDescription {
        padding-right: 30px;

        /*.ellipsis {
          @include crossMultiLine($font-size: $font-1125, $line-height: 1.2, $lines-to-show: 3, $excerpt-bg: transparent);
          margin-right: 60px;
        }*/
        /* > span {
           font-size: $font-1125;
         }*/

        .read-more {
          font-size: $font-1125;
          color: #2490E8;
          text-transform: uppercase;
          font-weight: bold;
          cursor:pointer;
        }
      }

      h3 {
        text-transform: capitalize;
        font-family: 'Roboto-Condensed', sans-serif;
       // font-weight: 700;
      }

      span.iconSpan {
        border: 1px solid $color-lightblue;
        border-radius: 50%;
        width: 124px;
        height: 124px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }
    }
  }

  .factors, .installations {

    text-align: center;

    p {
      max-width: 802px;
      margin: 0 auto;
    }
  }

  .factors {
    padding: 92px 0 30px;
    margin-bottom: 100px;
  }

  .installations {
    padding: 60px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 100px;
  }


  .service-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  @media (max-width: 767px) {
    .services {
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);

      .serviceDescription {
        padding: 50px 10px;
      }

      .allServices {
        .row {
          margin: 25px 0;
        }

        .shortDescription {
          padding: 10px 0;

          .read-more {
            display: none;
          }

          h3 {
            text-align: center;
            margin-bottom: 10px;
          }
        }

      }
    }
    .factors, .installations {
      text-align: left;
      margin-bottom: 20px;
    }
    .installations {
      padding: 20px 10px;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);

    }
    .factors {
      padding-top: 40px;

    }
    .service-description {
      h3 {
        text-align: center;
        padding: 20px 0 10px;
      }
    }
    #ventilation{
      .section-bg{
        margin-top:15px;
      }
    }
    #air-conditioning {
      .service-description {
        h3 {
          padding-top: 0;
        }
      }
    }
  }
}

