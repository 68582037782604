﻿@import "variables";

@font-face {
  font-family: 'Roboto', sans-serif;
  src: url('#{$roboto-font-path}Roboto-Regular/Roboto-Regular.ttf');
  src: url('#{$roboto-font-path}Roboto-Regular/Roboto-Regular.woff') format('woff'),
  url('#{$roboto-font-path}Roboto-Regular/Roboto-Regular.ttf') format('truetype'),
  url('#{$roboto-font-path}Roboto-Regular/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src: url('#{$roboto-font-path}Roboto-Bold/Roboto-Bold.ttf');
  src: url('#{$roboto-font-path}Roboto-Bold/Roboto-Bold.ttf') format('truetype'),
  url('#{$roboto-font-path}Roboto-Bold/Roboto-Bold.woff') format('woff'),
  url('#{$roboto-font-path}Roboto-Bold/Roboto-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Condensed', sans-serif;
  src: url('#{$roboto-font-path}Roboto-Condensed-Bold/Roboto-Condensed-Bold.ttf');
  src: url('#{$roboto-font-path}Roboto-Condensed-Bold/Roboto-Condensed-Bold.woff2') format('woff2'),
  url('#{$roboto-font-path}Roboto-Condensed-Bold/Roboto-Condensed-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

