#works {
  .container {

    .swiperHeight {
      height: 375px;
    }

    .btnsWrap {
      text-align: center;

      button {
        &:focus {
          outline: none;
        }
      }
    }
  }

  .work-block-small {
    margin-bottom: 30px;
    border: 1px solid $color-gray;

    > div {
      display: block;
      text-decoration: none;
      background: #fff;
      //@include br(3px);
      // @include box-shadow(0 7px 20px 0 rgba(#000, .06));
      @include transition(all .3s);
      @include clearfix;

      .work-block-small-img {
        position: relative;
        height: 243px;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        cursor: pointer;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          @include translate(-50%, -50%);
          max-width: 100%;
          display: block;
        }
      }

      .work-block-small-content {
        padding: 10px 20px;
        height: 102px;
        overflow: hidden;
        position: relative;
        transition: height 0.3s;
        @include clearfix;

        p {
          height: 54px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-weight: 400;
          margin-bottom: 10px;
        }

        h3 {
          //  color: $color-text-1;
          font-size: toEm(14);
          line-height: (20 / 14);
          height: 54px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-weight: 400;
          margin-bottom: 10px;
        }

        h4 {
          display: none;
          position: absolute;
          bottom: 20px;
          opacity: 0;
          transition: opacity 0.3s;
          transition-delay: 5s !important;

          span.view-more {
            font-size: $font-075;
            color: $color-lightblue;
            position: relative;
            text-transform: uppercase;
            cursor: pointer;
            transition: all 0.3s;

            &:after {
              content: '';
              border: 1px solid #72B1E4;
              position: absolute;
              width: 100%;
              bottom: -5px;
              left: 0;
            }

            &:hover {

            }
          }
        }
      }

      &:hover {
        @include box-shadow(0 12px 24px 0 rgba(#000, .16));

        .work-block-small-content {
          height: 147px;

          h4 {
            display: block;
            opacity: 1;
            transition: opacity 0.3s;
            transition-delay: 5s !important;
            span.view-more{
              font-size: $font-1125;
            }
          }

        }
      }
    }
  }



  .swiper-btn {
    border: 0;
    background: transparent;
    padding: 0.25rem 0.75rem;
    opacity: 0.5;

    &.swiper-button-prev {
      margin-right: 30px;
    }

    &:hover {
      @include box-shadow(0 3px 6px rgba(#000, .16));
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    .swiper-slide{
      max-width: 70vw;
    }

    .btnsWrap {
      display: none;
    }

    .container {
      .swiperHeight {
        height: 310px;
      }
    }

    .work-block-small {
      > div {
        .work-block-small-img {
          height: 168px;

        }

        .work-block-small-content {
          height: 139px;

          h4 {
            display: block;
            opacity: 1;

            span.view-more {
              font-size: $font-1;
            }
          }
        }
        &:hover{
          box-shadow: none;
          .work-block-small-content{
            height: 139px;
            h4{
              span.view-more{
                font-size: $font-1;
              }
            }
          }
        }
      }

    }
  }
}


.sliderDescription {
  padding: 25px 0;
}

