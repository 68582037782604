.aboutSection {
  p {
    word-break: break-word;
    &.sm {
      a, span {
        color: $color-blue;
      }
      a{
        /*position: relative;
        &:after{
          content: '';
          border: 1px solid $color-blue;
          position: absolute;
          width: 100%;
          bottom: -1px;
          left: 0;
        }*/

          text-decoration: underline;

      }
    }
  }

  .section-bg {
    margin-bottom: 20px;
  }
}