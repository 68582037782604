header {
  background: white;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);

  .navbar {
    padding: 0;
    .navbar-brand{
      cursor: pointer;
    }
    li {
      margin-right: 2.5rem;

      a {
        color: $color-black;
        font-size: $font-1125;
        cursor: pointer;
        text-transform: uppercase;
        font-family: 'Roboto-Condensed', sans-serif;
       // font-weight: 700;
      }

      .dropdown {
        svg {
          margin-left: 5px;
        }
      }
    }

    .navbar-collapse {
      justify-content: flex-end;
    }
  }

  @media (max-width: 767px) {
    .navbar-brand {
      img {
        width: 48px;
      }
    }
  }
}

.mobileMenu {
  background: white;
  position: absolute;
  left: 100%;
  opacity: 0;
  top: 0;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
  width: 268px;
  box-shadow: 10px 0 20px 0 rgba(0, 0, 0, 0.16);
  transition: opacity 0s 0.5s;
  animation: closeMenu 0.5s forwards;


  &.open {
    opacity: 1;
    transition-delay: 0s;
    animation: openMenu 0.5s forwards;
  }

  .langList {
    li {
      display: inline-block;
      padding: 0 15px;
      border-right: 1px solid $color-black;
      text-transform: uppercase;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        border: none;
      }

      a {
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }

  .closeBtn {
    top: 15px;
    right: 15px;
  }
}

@keyframes openMenu {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

@keyframes closeMenu {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}