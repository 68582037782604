body, html {
  color: $color-black;
  font-family: 'Roboto', sans-serif;
}


p, ul {
  margin: 0;
}

p {
  margin-bottom: 1rem;
}

section {
  margin-bottom: 120px;
  //padding-top: 18px;
  @media (max-width: 767px) {
    margin-bottom: 45px;
  }
}

.section-bg {
  background-size: cover;
  height: 100%;
  background-position: center;
  min-height: 666px;
  @media (max-width: 767px) {
    height: 343px;
    margin-left: -15px;
    margin-right: -15px;
    min-height: auto;
    &.serviceDescription {
      margin: 0
    }
  }
}

.sectionTitle {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 50px;
  font-family: 'Roboto Condensed', sans-serif;
  //font-weight: 700;

  span {
    position: relative;

    &:after {
      content: '';
      border: 2px solid #72B1E4;
      position: absolute;
      width: 50%;
      bottom: -10px;
      left: 25%;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 20px;
  }
}

p {
  &.sm {
    span, a {
      color: $color-lightblue;
    }
  }
}

.verticalAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}


//buttons
.closeBtn {
  background: transparent;
  border: 0;
  position: absolute;
  z-index: 5;
  right: 30px;
  top: 30px;

  &:focus {
    outline: none;
  }
}

a.btn-lightblue, button.btn-lightblue {
  background-color: $color-lightblue;
  color: white!important;
  cursor: pointer;
  padding: 18px 60px;
  font-size: $font-1125;
  font-family: "Roboto-Condensed", sans-serif;
  border-radius: 33px;
  text-transform: uppercase;

  &:hover {
    box-shadow: 0 12px 24px rgba(114, 177, 225, 0.35);
    color: white;
  }

  @media (max-width: 767px) {
    padding: 15px;
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1626px;
  }
}