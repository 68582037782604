﻿



/* 12px */ $font-075: 0.75rem;
/* 14px */ $font-0875: 0.875rem;
/* 16px */ $font-1: 1rem;
/* 18px */ $font-1125: 1.125rem;
/* 24px */ $font-15: 1.5rem;
/* 32px */ $font-2: 2rem;
/* 36px */ $font-2250: 2.250rem;
/* 46px */ $font-2875 :2.875rem;
/* 64px */ $font-4: 3.4rem;//4rem;


h1{
  font-size: $font-4;
  font-family: 'Roboto-Condensed', sans-serif;
}
h2{
  font-size: $font-2875;
  font-family: 'Roboto-Condensed', sans-serif;
}
h3{
  font-size: $font-2250;
  font-family: 'Roboto-Condensed', sans-serif;
  line-height: 1.5;
}
p{
  font-size: $font-15;
  line-height: 1.5;
  &.sm{
    font-size: $font-1125;
  }
  &.xs{
    font-size:$font-1;
  }
}

@media (max-width: 767px) {
  h1{
    font-size: $font-2;
    line-height: 1.5;
  }
  h2, h3{
    font-size: $font-15;
  }
  p{
    font-size: $font-1125;
    &.sm{
      font-size:  $font-1;
    }
    &.xs{
      font-size:$font-0875;
    }
  }
}