.homeSection {
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
  height: 100vh;
  color: white;
  font-family: "Roboto-Condensed", sans-serif;
  //font-weight: 700;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top:105px;
  &:after {
    content: '';
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, #fff 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    right: 0;
  }
  .homeInfo {
    max-width: 1000px;

    h1 {
      padding-bottom: 30px;
      text-transform: uppercase;
      word-break: break-word;
   // @include crossMultiLine($font-4 );
    }

    a {
      position: relative;
      z-index: 2;
      cursor: pointer;
    }
  }
  @media (max-width: 767px) {
    background-position: center center;
    padding-top:80px;
    height: auto;
    .homeInfo{
      p{
        margin-bottom: 2rem;
      }
    }
  }
}

