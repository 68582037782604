.modal-content {
  .modal-title {
    position: relative;
    height: 0;

    .closeBtn {
      right: 20px;
      top: 20px;
    }
  }

  .modal-body {
    padding: 30px;
     .sliderHeader{
       margin-top:-0.5rem;
     }
    .modalImg {
      background-repeat: no-repeat;
      background-size: cover;
      height: 549px;
    }

    .carousel-control-next, .carousel-control-prev {
      height: 32px;
      width: 32px;
      top: 310px ;
    //  bottom: 50%;
      background: white;
    }

    .carousel-control-prev {
      left: 30px;
    }

    .carousel-control-next {
      right: 30px;
    }

    .carousel-item {
      img {
        height: 549px;
      }
    }
  }

  @media (max-width: 767px) {
    .modal-title {
      height: 60px;

      .closeBtn {
        right: 10px;
        top: 10px;
      }
    }
    .modal-body {
      padding: 10px;
      max-height: 87vh;
      overflow: auto;

      .carousel-control-next, .carousel-control-prev {
        top: 170px;
      }
      .sliderHeader{
        margin-top: 0;
      }

      .modalImg {
        height: 264px;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}