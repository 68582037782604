@import "bootstrap/bootstrap";
@import './helpers/colors';
@import './helpers/fonts';
@import './helpers/mixins';
@import './fonts/styles';

//modules
@import "modules/header";
@import "modules/footer";
@import "modules/modalSlider";

//sections
@import "sections/home";
@import "sections/about";
@import "sections/services";
@import "sections/works";

//main styles
@import "common";
@import "svg";


