#mail, #viber, #air-conditioner, #long-arrow-right {
  .cls-1 {
    fill: #72b1e4;
  }
}
#whatsapp{
  &.cls-1 {
    fill: #72b1e4;
    fill-rule: evenodd;
  }
}
#telegram{
  &.cls-1 {
    fill: #72b1e4;
  }
}
#facebook{
  .cls-1 {
    fill: #3b5998;
  }
}

#heating-icon{
  .cls-1 {
    fill: #fff;
  }

  .cls-2 {
    fill: #202020;
  }

  .cls-3 {
    fill: #72b1e4;
  }
}


#ventilation-icon{
  .cls-1 {
    fill: #72b1e4;
  }

  .cls-2 {
    fill: none;
  }

  .cls-3 {
    fill: #202020;
  }
}
#down-arrow{
  .cls-1 {
    fill: #202020;
    stroke: #202020;
  }
}

#arrow-point-to-left, arrow-right{
  .cls-1 {
    fill: #202020;
  }
}
#burger-menu{
  .cls-1 {
    fill: #505050;
  }
}